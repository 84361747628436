:root{ 
    --white: #fff;
    --main-color: #32C392; 
}
.demo{ background-color: #e7e7e7; }
.serviceBox{
    color: var(--main-color);
    font-family: 'Zen Maru Gothic', sans-serif;
    text-align: center;
    padding: 30px 25px;
    position: relative;
    z-index: 1;
}
.serviceBox:before{
    content: "";
    background: #f4f4f4;
    border-radius: 40px 40px;
    box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.3);
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    top: 50%;
    left: 50%;
    z-index: -1;
}
.serviceBox:after{
    content: "";
    background: var(--main-color);
    width: 100%;
    height: calc(100% - 75px);
    border-radius: 50px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
}
.serviceBox .service-icon{
    color: var(--white);
    background: var(--main-color);
    font-size: 40px;
    line-height: 80px;
    height: 80px;
    width: 80px;
    margin: 0 auto 20px;
    border-radius: 50%;
}
.serviceBox .title{
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 12px;
}
.serviceBox .description{
    color: #444;
    font-size: 15px;
    line-height: 22px;
}
.serviceBox.blue{ --main-color: #359BC8; }
.serviceBox.yellow{ --main-color: #f37f12; }
.serviceBox.purple{ --main-color: #5557B4; }
.serviceBox.pink{ --main-color: #ff27c0; }
@media only screen and (max-width: 990px){
    .serviceBox{ margin: 0 10px 30px; }
}
